import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Integrate from '../Integrate';

import * as styles from './Landing.module.scss';

function Landing() {
  return (
    <div className={styles.container}>
      <h3 className={styles.subheading}>
        Is your Ads Revenue <span className={styles.red}>decreasing</span> due to
        Ads Blocker?
      </h3>

      <h1 className={styles.heading}>
        Integrate <span className={styles.red}>Tracy</span> with your website and
        bypass Ads Blockers
      </h1>

      <OutboundLink
        href='https://www.producthunt.com/posts/tracy-3'
        rel='noreferrer noopener'
        target='_blank'
      >
        <img
          src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=tracy-3&theme=light'
          alt='Tracy | Product Hunt'
          width='250'
          height='54'
        />
      </OutboundLink>

      <Integrate />
    </div>
  );
}

export default Landing;
