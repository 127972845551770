import React, { useContext } from 'react';
import { navigate } from 'gatsby';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, TextField } from '@mui/material';
import isBrowser from '../../utils/isBrowser';

import { adsProviders } from '../../constants';

import { UserContext } from '../../context/UserContext';

import * as styles from './Integrate.module.scss';

function Integrate() {
  const { adsProvider, email, handleChange } = useContext(UserContext);

  return (
    <div className={styles.container}>
      <FormControl variant='standard' sx={{ minWidth: 200 }}>
        <InputLabel id='select-ads-provider-label'>Ads Provider</InputLabel>

        <Select
          labelId='select-ads-provider-label'
          value={adsProvider.value}
          onChange={handleChange}
          label='Ads Provider'
          name='adsProvider'
        >
          {adsProviders.map(provider => (
            <MenuItem key={provider} value={provider}>
              {provider}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        sx={{ minWidth: 250 }}
        id='standard-basic'
        label='Email'
        variant='standard'
        type='email'
        name='email'
        onChange={handleChange}
        value={email.value}
      />

      <Button
        className={styles.btn}
        variant='contained'
        onClick={() => {
          isBrowser &&
            window.gtag('event', 'click', {
              category: 'Button',
              action: 'Click',
              label: '1st Page Button',
            });

          navigate('/waitlist');
        }}
      >
        Join Waitlist
      </Button>
    </div>
  );
}

export default Integrate;
